import React from 'react'
import { graphql } from 'gatsby'
import SearchableSchoolsListing from '../../SearchableSchoolsListing'

export const ParagraphSearchableSchoolsListingFields = graphql`
  fragment ParagraphSearchableSchoolsListingFields on paragraph__searchable_schools_listing {
    __typename
    id
  }
`

const ParagraphSearchableSchoolListing = () => <SearchableSchoolsListing />

export default ParagraphSearchableSchoolListing
