enum DistanceFromPostalCode {
  WITHIN_1_MILES = 'within1Miles',
  WITHIN_2_MILES = 'within2Miles',
  WITHIN_3_MILES = 'within3Miles',
  WITHIN_5_MILES = 'within5Miles',
  WITHIN_8_MILES = 'within8Miles',
  WITHIN_13_MILES = 'within13Miles',
  WITHIN_21_MILES = 'within21Miles',
}

export default DistanceFromPostalCode
