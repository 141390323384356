import React, { useState } from 'react'
import DistanceFromPostalCode from '../../types/DistanceFromPostalCode'

const filterContextDefaultValue = {
  distanceSelectorVisible: false,
  distanceFromPostalCode: DistanceFromPostalCode.WITHIN_1_MILES,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDistanceFromPostalCode: (_: DistanceFromPostalCode) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDistanceSelectorVisible: (_: boolean) => {},
}

export const FilterContext = React.createContext(filterContextDefaultValue)

type FilterContextProviderProps = React.PropsWithChildren<{}>

const FilterContextProvider = ({ children }: FilterContextProviderProps) => {
  const [state, setState] = useState(filterContextDefaultValue)

  const setDistanceFromPostalCode = (d: DistanceFromPostalCode) => {
    setState({ ...state, distanceFromPostalCode: d })
  }

  const setDistanceSelectorVisible = (b: boolean) => {
    setState({ ...state, distanceSelectorVisible: b })
  }

  return (
    <FilterContext.Provider
      value={{
        ...state,
        setDistanceFromPostalCode,
        setDistanceSelectorVisible,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContextProvider
