import debounce from 'lodash/debounce'
import React, { useContext, useState } from 'react'
import { navigate } from 'gatsby'
import qs from 'qs'
import algoliasearch from 'algoliasearch'
import { InstantSearch } from 'react-instantsearch-dom'
import FilterContextProvider, { FilterContext } from '../../context/FilterContext'

const algoliaSearchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID || '29TNHH9D6B',
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY || 'ff1b3e7b124f812523a64c73831f9881',
)

type AlgoliaSearchIndexProps = React.PropsWithChildren<{ indexName: string }>

const createQueryString = (searchState: any) => `?${qs.stringify(searchState)}`

const searchStateToUrl = (searchState: any): string => {
  if (typeof window === 'undefined') {
    return ''
  }

  // check if no filters selected
  const noQuery = !searchState.query
  const noFilters =
    !searchState.refinementList ||
    Object.values(searchState.refinementList).every((filter: any) => filter.length === 0)
  const noSearch = noQuery && noFilters

  return noSearch
    ? window.location.pathname
    : `${window.location.pathname}${createQueryString(searchState)}`
}

const urlToSearchState = () => {
  if (typeof window === 'undefined') {
    return {}
  }

  return qs.parse(window.location.search.slice(1))
}

const navigateTo = (url: string) => navigate(url)
const navigateDebounced = debounce(navigateTo, 50)

const AlgoliaSearchIndex = ({ children, indexName }: AlgoliaSearchIndexProps) => {
  const [searchState, setSearchState] = useState(urlToSearchState())
  const { distanceSelectorVisible, setDistanceSelectorVisible } = useContext(FilterContext)

  const onSearchStateChange = (updatedSearchState: any) => {
    const showDistanceSelector =
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within1Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within2Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within3Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within5Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within8Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within13Miles'] ||
      !!updatedSearchState?.refinementList?.['nearbyPostalCodes.within21Miles'] ||
      distanceSelectorVisible

    setDistanceSelectorVisible(showDistanceSelector)
    navigateDebounced(searchStateToUrl(updatedSearchState))
    setSearchState(updatedSearchState)
  }

  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName={indexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createQueryString}
    >
      {children}
    </InstantSearch>
  )
}

const WrappedAlgoliaSearchIndex = ({ indexName, children }: AlgoliaSearchIndexProps) => (
  <FilterContextProvider>
    <AlgoliaSearchIndex indexName={indexName}>{children}</AlgoliaSearchIndex>
  </FilterContextProvider>
)

export default WrappedAlgoliaSearchIndex
