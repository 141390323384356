/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import Select from 'react-select'
import { LanguageContext } from '../../context/LanguageContext'

const MenuSelect = ({ refine, items }: any) => {
  const { t } = useContext(LanguageContext)

  return (
    <Select
      id="zip"
      isClearable
      className="rounded-full"
      classNamePrefix="react-select"
      placeholder={t('Zip')}
      options={items}
      onChange={(nextValue) => refine(nextValue?.value ? nextValue.value : '')}
    />
  )
}

export const PostalCodeSearch = connectRefinementList(MenuSelect)
