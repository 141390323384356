import React, { useContext } from 'react'
import { RefinementItem, RefinementList } from 'react-instantsearch-dom'
import { sortBy } from 'lodash'
import { LanguageContext } from '../../context/LanguageContext'
import DistanceFromPostalCodeFilter from './DistanceFromPostalCodeFilter'

// @ts-ignore
import { GRADE_NAMES } from '../../grades'

const Filters = () => {
  const { t } = useContext(LanguageContext)

  return (
    <>
      <h3 className="mb-6 font-bold">{t('Filter by:')}</h3>

      <DistanceFromPostalCodeFilter />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Grade Level')}</h3>
      <RefinementList
        attribute="relationships.field_program.title"
        transformItems={(items: Array<RefinementItem<{}>>) => sortBy(items, 'label', 'asc')}
        limit={20}
      />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Specialties')}</h3>
      <RefinementList attribute="relationships.field_specialties.title" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Arts')}</h3>
      <RefinementList attribute="field_visual_and_performing_arts" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Features')}</h3>
      <RefinementList attribute="additionalFeatures" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Career Pathways')}</h3>
      <RefinementList attribute="relationships.field_career_pathways.title" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Awards')}</h3>
      <RefinementList attribute="relationships.field_awards.title" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Communities')}</h3>
      <RefinementList attribute="relationships.field_communities.title" limit={20} />

      <h3 className="mt-6 mb-2 text-gray-600 uppercase text-sm">{t('Grades')}</h3>
      <RefinementList
        operator="and"
        transformItems={(items: any[]) =>
          items
            .sort((a: any, b: any) => GRADE_NAMES.indexOf(a.label) - GRADE_NAMES.indexOf(b.label))
        }
        attribute="field_grades"
        limit={20}
      />
    </>
  )
}

export default Filters
