import React, { useContext, useState, useEffect } from 'react'
import {
  Configure,
  SearchBox,
  connectStateResults,
  connectHits,
  Pagination,
} from 'react-instantsearch-dom'
import { LanguageContext } from '../../context/LanguageContext'
import AlgoliaSearchIndex from '../AlgoliaSearchIndex'
import SchoolPreview from '../SchoolPreview'
import Filters from '../Filters'
import Button from '../Button'
import { getWindow } from '../../utils'
import './styles.css'

interface LoadingIndicatorProps {
  searching: boolean
}
const LoadingIndicatorInner = ({ searching }: LoadingIndicatorProps) => {
  const { t } = useContext(LanguageContext)

  return searching ? <p>{t('Searching...')}</p> : null
}
const LoadingIndicator = connectStateResults(LoadingIndicatorInner)

const HitsInner = ({ hits }: { hits: any[] }) => (
  <ul className="searchable-schools-listing__list">
    {hits.map((hit) => (
      <SchoolPreview key={hit.objectID} school={hit} />
    ))}
  </ul>
)
const Hits = connectHits(HitsInner)

const SearchableSchoolsListing = () => {
  const { langcode, t } = useContext(LanguageContext)
  const [modalIsOpen, setIsOpen] = useState(false)

  function toggleModal() {
    setIsOpen(!modalIsOpen)
  }

  useEffect(() => {
    if (getWindow()) {
      ;(() => {
        const body = document?.querySelector?.('body')
        if (!body) return

        if (modalIsOpen) {
          body.style.overflow = 'hidden'
        } else {
          body.style.overflow = ''
        }
      })()
    }

    return () => {
      if (getWindow()) {
        const body = document?.querySelector?.('body')
        if (!body) return

        body.style.overflow = ''
      }
    }
  }, [modalIsOpen])

  return (
    <AlgoliaSearchIndex indexName={process.env.GATSBY_CLOUD ? 'prod_schools' : 'dev_schools'}>
      <Configure filters={`langcode:${langcode}`} hitsPerPage={30} />

      <div
        className={`fixed ${
          modalIsOpen ? 'inset-y-0 lg:hidden' : '-inset-full lg:hidden'
        } overflow-y-scroll h-full -ml-4 p-4 bg-white w-full z-50`}
      >
        <Button className="mb-4" onClick={() => toggleModal()}>
          {t('Close Filters')}
        </Button>
        <div className="ml-1">
          <Filters />
        </div>
      </div>

      <section className="max-w-7xl p-1 md:p-0 md:pt-2">
        <div className="grid gap-8 lg:grid-cols-3">
          <div className="hidden lg:block">
            <Filters />
          </div>
          <div className="col-span-2">
            <Button className="mb-4 lg:hidden" onClick={() => toggleModal()}>
              {t('Open filters')}
            </Button>
            <h3 className="mb-2 font-bold">{t('Search by name')}</h3>
            <SearchBox translations={{ placeholder: () => t('Search here...') }} />
            <div className="mb-8" />
            <LoadingIndicator />
            <Hits />
            <Pagination />
          </div>
        </div>
      </section>
    </AlgoliaSearchIndex>
  )
}

export default SearchableSchoolsListing
