import React, { useContext } from 'react'
import { FilterContext } from '../../../context/FilterContext'
import { LanguageContext } from '../../../context/LanguageContext'
import DistanceFromPostalCode from '../../../types/DistanceFromPostalCode'
import { PostalCodeSearch } from '../../PostalCodeSearch'
import RenderIf from '../../RenderIf'

const DistanceFromPostalCodeFilter = () => {
  const { t } = useContext(LanguageContext)
  const { distanceFromPostalCode, setDistanceFromPostalCode } = useContext(FilterContext)

  return (
    <div className="sm:flex">
      <div className="mb-2 sm:mb-0">
        <label htmlFor="distance-select" className="block mb-2 text-gray-400 uppercase text-sm">
          {t('Distance')}
        </label>
        <select
          id="distance-select"
          className="bg-gray-100 px-2 py-3 rounded-md text-sm"
          value={distanceFromPostalCode}
          onChange={(e) => setDistanceFromPostalCode(e.target.value as DistanceFromPostalCode)}
        >
          <option value={DistanceFromPostalCode.WITHIN_1_MILES}>{t('< 1 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_2_MILES}>{t('< 2 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_3_MILES}>{t('< 3 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_5_MILES}>{t('< 5 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_8_MILES}>{t('< 8 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_13_MILES}>{t('< 13 mi.')}</option>
          <option value={DistanceFromPostalCode.WITHIN_21_MILES}>{t('< 21 mi.')}</option>
        </select>
        <span className="mx-2 text-gray-700">&rarr;</span>
      </div>
      <div>
        <label className="mb-2 text-gray-400 uppercase text-sm block" htmlFor="zip">
          {t('My Zip')}
        </label>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_1_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within1Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_2_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within2Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_3_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within3Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_5_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within5Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_8_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within8Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_13_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within13Miles" limit={200} />
        </RenderIf>
        <RenderIf condition={distanceFromPostalCode === DistanceFromPostalCode.WITHIN_21_MILES}>
          <PostalCodeSearch attribute="nearbyPostalCodes.within21Miles" limit={200} />
        </RenderIf>
      </div>
    </div>
  )
}

export default DistanceFromPostalCodeFilter
